import React,{useEffect} from "react";
import { useDispatch,useSelector } from "react-redux";
import { message } from "antd";
import {getByIdusers, updateusers } from "../../store/usersSlice";

const ProfileManagement = () => {


  const userId = localStorage.getItem("userRegister");
  console.log(userId);
  
  
  const dispatch =useDispatch()
  

  const { data: users, status2 } = useSelector((state) => state?.users);
  console.log("ddddddddddddd", users);

  useEffect(()=>{
    dispatch(getByIdusers(userId))
    
  },[])



  const handle = (values) => {
   
      dispatch(updateusers(userId,{
        username: values.username.value,
        email: values.email.value,
        phone: values.phone.value,
        password: values.password.value,
        // confirmPassword:values.confirmPassword.value
      }));
    
  };
  return (
    <>
      <div className="m-4">
        <h3 className="text-start p-2">Profile Management</h3>

        <div className=" card rounded border-0 p-4 shadow bg-white">
          <form onSubmit={(e) => { e.preventDefault(); handle(e.target) }}>
          <div className=" row g-3">
            <h4>Profile Management</h4>

            <div className="col-md-6">
              <label>Username</label>
              <input
                type="text"
                name="username"
                className="form-control"
                placeholder="Doctor Name"
                defaultValue={users?.username}
              />
            </div>

            <div className="col-md-6">
              <label>Email</label>
              <input type="text" name="email" className="form-control " 
              defaultValue={users?.email}
              />
            </div>

            <div className="col-md-6">
              <label>Phone</label>
              <input type="text" name="phone" placeholder="" className="form-control" 
              defaultValue={users?.phone}
              />
            </div>

            <div className="col-md-6">
              <label>Password</label>
              <input type="password" name="password" className="form-control" 
              defaultValue={users?.password}
              />
            </div>

            <div className="col-md-12">
              <button type="Register" className="btn btn-primary">
                Update
              </button>
            </div>
          </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default ProfileManagement;
