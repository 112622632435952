import React from "react";
import { Link } from "react-router-dom";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { useSelector, useDispatch } from "react-redux";
import { createcard } from "../../store/cardSlice";
import { message } from "antd";
function RegistrationCard() {
  const dispatch = useDispatch();
  $(document).ready(function () {
    setTimeout(function () {
      $("#AllData").DataTable({
        pagingType: "full_numbers",
        pageLength: 10,
        processing: true,
        bDestroy: true,
        // dom: 'Bfrtip',
        // buttons: ['copy', 'csv', 'print']
      });
    }, 1000);
  });

  const userId = localStorage.getItem("userRegister");
  console.log(userId);

  const handle = (values) => {
    if (values.name.value.length === 0) {
      message.error("Must enter name");
    } else if (values.mr.value.length === 0) {
      message.error("Must enter mr");
    } 
    else if (values.relative.value.length === 0) {
      message.error("Must enter mr");
    }
    else if (values.age.value.length === 0) {
      message.error("Must enter age");
    } else if (values.date.value.length === 0) {
      message.error("Must enter date");
    } else if (values.phone.value.length === 0) {
      message.error("Must enter phone");
    } else if (values.address.value.length === 0) {
      message.error("Must enter address");
    } else {
      dispatch(
        createcard({
          
          name:values.name.value,
          mr:values.mr.value,
          relative:values.relative.value,
          userId: userId,
          age:values.age.value,
          date:values.date.value,
          address:values.address.value,
          phone:values.phone.value,

        })
      );
    }
  };

  return (
    <>
      <div className="container py-4">
        <div className="row">
          <div className="col-md-10  mx-auto">
            <div className="card p-3">
              <div className="text-center">
                <h2>BACH  CHRISTIAN HOSPITAL P.O QALAND ARABAD</h2>
                <h2>Phone: 0992-370007, 370108</h2>
              </div>

              <div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    handle(e.target);
                  }}
                >
                  <div className=" row g-3">
                    <div className="col-md-6">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder=""
                        name="name"
                      />
                    </div>

                    <div className="col-md-6">
                      <label>MR #</label>
                      <input type="text" className="form-control " name="mr" />
                    </div>

                    <div className="col-md-6">
                      <label>Relative:</label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control"
                        name="relative"
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Age:</label>
                      <input
                        type="text"
                        placeholder=""
                        className="form-control "
                        name="age"
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Date:</label>
                      <input type="date" className="form-control" name="date"  />
                    </div>

                    <div className="col-md-6">
                      <label>Address:</label>
                      <input
                        type="text"
                        className="form-control"
                        name="address"
                      />
                    </div>

                    <div className="col-md-6">
                      <label>Phone#</label>
                      <input
                        type="phone"
                        className="form-control"
                        name="phone"
                      />
                    </div>
                    <div className="col-md-6">
                      
                    </div>

                    <div className="col-md-12">
                      <button type="submit" className="btn btn-primary">
                        Save
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* 
      <div className="m-4">
        <h3 className="text-start p-2">Registration Card</h3>
        <div className=" container-fluid bg-white  rounded-3 shadow">
          <div className="col-md-12 text-end pt-4">
            <div className="table-responsive py-3">
              <table class="table text-center table-striped" id="AllData">
                <thead>
                  <tr>
                    <th scope="col">SL</th>
                    <th scope="col">Customer Name</th>
                    <th scope="col">Customer Number</th>
                    <th scope="col">List Appointment</th>

                    <th scope="col">Card Number</th>
                    <th scope="col">Status</th>
                    <th scope="col">Accept</th>
                    <th scope="col">Reject</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td scope="row">1</td>
                    <td>Ali</td>
                    <td>UBL</td>

                    <td>12121-12-12</td>
                    <td className="text-primary pt-3">Pending</td>
                    <td>
                      <Link
                        type="Register"
                        to="/make_appointment"
                        className="btn btn-info text-white"
                      >
                        Accept
                      </Link>
                    </td>
                    <td>
                      <Link
                        type="Register"
                        to="/make_appointment"
                        className="btn btn-info text-white"
                      >
                        Reject
                      </Link>
                    </td>
                    <td>
                      <div className=" d-flex justify-content-between">
                        <i
                          style={{ color: "blue", fontSize: "20px" }}
                          class="bi bi-pencil-square"
                        ></i>
                        <i
                          style={{ color: "#a0252b", fontSize: "20px" }}
                          class="bi bi-trash3"
                        ></i>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
}

export default RegistrationCard;
