import React from 'react'
import { message } from 'antd';
import { useNavigate } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { loginusers } from '../store/usersSlice';


const Login = () => {

    const navigate = useNavigate();
    const dispatch=useDispatch()



    const handle = (values) => {
    
        if (values.username.value.length === 0) {
          message.error('Must enter username')
        }
        else if (values.password.value.length === 0) {
          message.error('Must enter password')
        }
        
        else {
    
          dispatch(loginusers({'username':values.username.value,'password':values.password.value}));
        }
    
      };




    return (
        <div>
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div className="col-md-6">
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <div className="text-center mb-3">
                                            <a href="index.html">
                                                <img src={require('../logo.png')} alt="logo" width='140px' />
                                            </a>
                                        </div>
                                        <h4 className="text-center mb-4">Sign in your account</h4>
                                        <form onSubmit={(e) => { e.preventDefault(); handle(e.target) }}>
                                            <div className="form-group text-start mb-3">
                                                <label className="mb-1">
                                                    <strong>Email</strong>
                                                </label>
                                                <input
                                                id="username"
                                                name="username"
                                                    type="text"
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-group text-start">
                                                <label className="mb-1">
                                                    <strong>Password</strong>
                                                </label>
                                                <input
                                                 id='password'
                                                 name='password'
                                                    type="password"
                                                    className="form-control"
                                                />
                                            </div>
                                            <div className="form-row d-flex justify-content-between mt-4 mb-2">
                                                <div className="form-group">
                                                    <div className="form-check custom-checkbox ms-1">
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            id="basic_checkbox_1"
                                                        />
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor="basic_checkbox_1"
                                                        >
                                                            Remember my preference
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="text-center">
                                                <button type="submit"
                                                className="btn btn-primary btn-block w-100">
                                                    Sign Me In
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}

export default Login
