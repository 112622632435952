import logo from './logo.svg';
import './App.css';

import Login from './Pages/Login';
import OutRoutes from './Pages/OutRoutes';
import { Route, Routes } from 'react-router-dom';
import Dashboard from './Pages/Dashboard';
import HistoryTranscation from './Pages/HistoryTranscation/HistoryTranscation';
import LabReports from './Pages/LabReports/LabReports';
import MakeAppointment from './Pages/MakeAppointment/MakeAppointment';
import MakeDonation from './Pages/MakeDonation/MakeDonation';
import ProfileManagement from './Pages/ProfileManagemnt/ProfileManagement';
import TableTranscation from './Pages/HistoryTranscation/TableTranscation';
import LabeTable from './Pages/LabReports/LabeTable';
import AppointmentTable from './Pages/MakeAppointment/AppointmentTable';
import RegistrationCard from './Pages/RegistrationCard/RegistrationCard';
import Edit_Appointment from './Pages/MakeAppointment/Edit_Appointment';

function App() {
  return (
    <Routes>
        <Route path='/' element={<Login/>} />
        <Route element={<OutRoutes/>}>
        <Route path='/dashboard' element={<Dashboard/>} />
        <Route path='/history_transcation' element={<HistoryTranscation/>} />
        {/* <Route path='/TabelTranscation' element={<TableTranscation/>} /> */}
        <Route path='/lab_reports' element={<LabReports/>} />
        <Route path='/lab_Tabel' element={<LabeTable/>} />
        <Route path='/make_appointment' element={<MakeAppointment/>} />
        <Route path='/edit_appointment/:id' element={<Edit_Appointment/>} />
        <Route path='/AppointTable' element={<AppointmentTable/>} />
        <Route path='/make_donation' element={<MakeDonation/>} />
        <Route path='/profile_management' element={<ProfileManagement/>} />
        <Route path='/registration_card' element={<RegistrationCard/>}  />
        
        </Route>
    </Routes>
  );
}

export default App;
