import React,{useEffect} from 'react'
import {LineChart,XAxis,YAxis,Tooltip,CartesianGrid,Line,Legend,ResponsiveContainer} from 'recharts'
import { useSelector,useDispatch } from 'react-redux';
import { fetchusers } from '../store/usersSlice';
const LineCharts = () => {
  const dispatch = useDispatch();

  const { data: users, status } = useSelector((state) => state?.users);
  console.log('ddddddddddddd', users);
  useEffect(() => {
    dispatch(fetchusers());
  }, [])

    
      
      const data=users?.length>0?users && users?.map(({createdAt, id})=>{ 

        return {id, createdAt};
      
      }):0;
      
  return (
    <ResponsiveContainer width="100%" aspect={3}> 
   <LineChart width={830} height={250} data={data}
  margin={{ top: 5, right: 30, left: 20, bottom: 5 }}>
  <CartesianGrid strokeDasharray="3 3" />
  <XAxis dataKey="createdAt" />
  <YAxis />
  <Tooltip />
  <Legend />
  <Line type="monotone"  dataKey="id" stroke="#8884d8" />
  
</LineChart>
    </ResponsiveContainer>
 
  )
}

export default LineCharts