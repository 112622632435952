import axios from 'axios'
import { message } from "antd";
import api_url from './ApiUrl';
const { createSlice } = require("@reduxjs/toolkit")

const STATUS = Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const appointmentSlice = createSlice({
    name: "appointment",
    initialState: {
        data: [],
        update:'',
        status: STATUS.IDLE,
    },

    reducers: {
        setappointment(state, action) {
            state.data = action.payload
        },
       setStatus(state, action) {
            state.status = action.payload
        }

    }

})

export const { setappointment, setStatus,getappointment } = appointmentSlice.actions

export default appointmentSlice.reducer

// thunk

export function fetchappointment(id) {
    return async function fetchappointmentThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.put(`${api_url}/appointment/getSingle/${id}`).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setappointment(resp.data.data[0]))
                })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}














export function createappointment(param) {
    return async function createappointmentThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.post(`${api_url}/appointment/create`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("appointment Created Successfully!")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/AppointTable`;
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function loginappointment(param) {
    return async function createappointmentThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.post(`${api_url}/login/create`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success(res.data?.message)
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/dashboard`;
                    localStorage.setItem('userRegister', res.data?.data?.id)
                }else{
                    message.error(res.data?.status)
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}





export function updateappointment(id,param) {
    return async function updateappointmentThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {
         
            await axios.put(`${api_url}/appointment/update/${id}`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("appointment Updated")
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/AppointTable`;
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}






export function deleteappointment(id) {
    return async function deleteappointmentThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.delete(`${api_url}/appointment/delete/${id}`).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    window.location.reload(true)
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}






export function getByIdappointment(id) {
    return async function getByIdappointmentThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.get(`${api_url}/appointment/get/${id}`).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setappointment(resp.data.data))
                })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function getByIdSappointment(id,params) {
    return async function getByIdSappointmentThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.put(`${api_url}/appointment/getSingle/${id}`,params).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setappointment(resp.data.data[0]))
                })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}