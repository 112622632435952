import React from 'react'



const LabReports = () => {
  return (
    <>


<div className=' m-5'>
      <h3 className='text-start p-2'>Labe Reports</h3>

      <div className=' card rounded shadow bg-white'>
     
    <div className=' row  p-3  d-flex justify-content-between'>

<div className='col-md-6'>
  <label> Add Button </label>
<input type="text" className='form-control' />
</div>

<div className='col-md-6'>
<label> Name Of Customer</label>
<input type="text" className='form-control ' />
</div>
</div>

<div className=' row  p-3 d-flex justify-content-between'>

<div className='col-md-6'>
  <label> Date of Added </label>
<input type="text" className='form-control' />
</div>

<div className='col-md-6'>
<label> Customer Report Details </label>
<input type="text" className='form-control ' />
</div>
</div>
<div className=' row  p-3 d-flex justify-content-between'>

<div className='col-md-6'>
  <label> Attachment of Reports </label>
<input type="text" className='form-control' />
</div>

<div className='col-md-6'>
<label> Summit Option </label>
<input type="text" className='form-control ' />
</div>
</div>
<div className=' row  p-3 d-flex justify-content-between'>

<div className='col-md-6'>
  <label> Same as Labe Reports </label>
<input type="text" className='form-control' />
</div>

<div className='col-md-6'>
<label> Eddit Option </label>
<input type="text" className='form-control ' />
</div>
</div>
<div className=' row  p-3 d-flex justify-content-between'>




</div>
<div className=' row  p-3 d-flex justify-content-between'>

<div className='col-md-12'>
<button type="Register" className='btn btn-primary' >Register</button>
</div>


</div>

</div>
</div>       
       
       

    </>
  )
}

export default LabReports