import axios from 'axios'
import { message } from "antd";
import api_url from './ApiUrl';
const { createSlice } = require("@reduxjs/toolkit")

const STATUS = Object.freeze({
    IDLE: "idle",
    ERROR: "error",
    LOADING: "loading",
})


const DoctorSlice = createSlice({
    name: "Doctor",
    initialState: {
        data: [],
        update:'',
        status: STATUS.IDLE,
    },

    reducers: {
        setDoctor(state, action) {
            state.data = action.payload
        },
       setStatus(state, action) {
            state.status = action.payload
        }

    }

})

export const { setDoctor, setStatus,getDoctor } = DoctorSlice.actions

export default DoctorSlice.reducer

// thunk

export function fetchDoctor() {
    return async function fetchDoctorThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.get(`${api_url}/Doctor/get`).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setDoctor(resp.data.data))
                })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}





export function createDoctor(param) {
    return async function createDoctorThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.post(`${api_url}/Doctor/create`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("Doctor Created Successfully!")
                  
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}



export function loginDoctor(param) {
    return async function createDoctorThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.post(`${api_url}/login/create`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success(res.data?.message)
                    const main_urls=window.location.origin
                    window.location.href=`${main_urls}/#/dashboard`;
                    localStorage.setItem('userRegister', res.data?.data?.id)
                }else{
                    message.error(res.data?.status)
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}





export function updateDoctor(id,param) {
    return async function updateDoctorThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {
         
            await axios.put(`${api_url}/Doctor/update/${id}`, param).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    message.success("Doctor Updated")
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}






export function deleteDoctor(id) {
    return async function deleteDoctorThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.delete(`${api_url}/Doctor/delete/${id}`).then(res => {
                console.log(res.data)
                if (res.data?.status === "ok") {
                    window.location.reload(true)
                }
            })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}






export function getByIdDoctor(id) {
    return async function getByIdDoctorThunk(dispatch, getState) {
        dispatch(setStatus(STATUS.LOADING))
        try {

            await axios.get(`${api_url}/Doctor/get/${id}`).then(
                (resp) => {
                    console.log(resp.data);
                    dispatch(setDoctor(resp.data.data))
                })
            dispatch(setStatus(STATUS.IDLE))
        } catch (err) {
            console.log(err);
            dispatch(setStatus(STATUS.ERROR))
        }
    }
}