import React,{useEffect} from 'react'
import { Link } from 'react-router-dom'
import "datatables.net-dt/js/dataTables.dataTables"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import "datatables.net-buttons/js/dataTables.buttons.js"
import "datatables.net-buttons/js/buttons.colVis.js"
import "datatables.net-buttons/js/buttons.flash.js"
import "datatables.net-buttons/js/buttons.html5.js"
import "datatables.net-buttons/js/buttons.print.js"
import "datatables.net-dt/css/jquery.dataTables.min.css"
import { useDispatch,useSelector } from 'react-redux'
import $ from 'jquery';
import { fetchlab } from '../../store/labSlice'
function LabeTable() {

  const dispatch =useDispatch()
  
  const { data: lab, status2 } = useSelector((state) => state?.lab);
  console.log("ddddddddddddd", lab);

  useEffect(()=>{
    dispatch(fetchlab(localStorage.getItem("userRegister")))
    
  },[])
  $(document).ready(function () {
    setTimeout(function () {
      $('#AllData').DataTable(
        {
          pagingType: 'full_numbers',
          pageLength: 10,
          processing: true,
          bDestroy: true,
          // dom: 'Bfrtip',
          // buttons: ['copy', 'csv', 'print']
        }
      );
    },
      1000
    );
  });
  return (
    <>

<div className='m-4'>

<div className=' card containe border-0 bg-white p-4 shadow'>

   <div className="row pb-4 align-items-center" >
                <div className="col-md-6">
                <h3>Lab Reports</h3>
                </div>
                
              </div>
<table class="table align-middle" id='AllData' >
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name of Customer</th>
      <th scope="col">Description</th>
      <th scope="col">Attachment Reports</th>
      <th scope="col">Week</th>
      <th scope="col">Date</th>

      {/* <th scope='col'>Option</th> */}


    </tr>
  </thead>
  <tbody>
   
    {lab?.lab && lab?.lab?.map((item,index)=>{
      return(
        <>
         <tr>
      <th scope="row">{index+1}</th>

      <td>{lab?.username}</td>
     
    
      <td>{item?.description}</td>
      
      <td>

        {JSON.parse(item?.attachment).map(i=>
        <a href={i} download target="_blank">
        <img src={i} className='rounded-circle' width="40px" height="40px" alt='' />
        </a>
          )}
      </td>
      <td>{item?.doctor?.week}</td>
      <td>{item?.createdAt}</td>
      {/* <td>
      <div className=" d-flex justify-content-between">
<i     style={{ color: "blue", fontSize: "20px" }} class="bi bi-pencil-square"></i>
<i     style={{ color: "#a0252b", fontSize: "20px" }} class="bi bi-trash3"></i>
  
</div>
      </td> */}
   

    </tr>
        </>
      )
    })}
  </tbody>
</table>







</div>








</div>



    </>
  )
}

export default LabeTable