import React, { useEffect,useState } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { message, Modal } from "antd";
import "datatables.net-dt/js/dataTables.dataTables";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-buttons/js/dataTables.buttons.js";
import "datatables.net-buttons/js/buttons.colVis.js";
import "datatables.net-buttons/js/buttons.flash.js";
import "datatables.net-buttons/js/buttons.html5.js";
import "datatables.net-buttons/js/buttons.print.js";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import $ from "jquery";
import { fetchdonation } from "../../store/donationSlice";
import axios from "axios";
import api_url from "../../store/ApiUrl";
function HistoryTranscation() {







   
  const [donation,setdonation]=useState([])
  console.log(donation,'donation');
  const dataDon=() => {
    axios.put(`${api_url}/donation/getSingle/${localStorage.getItem("userRegister")}`).then(
      (resp) => {
        console.log(resp.data)
          setdonation(resp.data.data)
      });

    
  };


  const [appointment,setappointment]=useState([])
  
  const dataApp=() => {
    axios.put(`${api_url}/appointment/getSingle/${localStorage.getItem("userRegister")}`).then(
      (resp) => {
        console.log(resp.data)
          setappointment(resp.data.data)  
      });

      
  };


  const [card,setcard]=useState([])
  const dataCard=() => {
    axios.put(`${api_url}/card/getSingle/${localStorage.getItem("userRegister")}`).then(
      (resp) => {
        console.log(resp.data)
          setcard(resp.data.data)
      });


      
  };




  const filterDataApp=(e)=>{
    

    const startDateApp=document.getElementById('startDateApp').value
    const endDateApp=document.getElementById('endDateApp').value

    axios.put(`${api_url}/appointment/getSingle/${localStorage.getItem("userRegister")}`,{'startDate':startDateApp,'endDate':endDateApp}).then(
      (resp) => {
        console.log(resp.data)
          setappointment(resp.data.data)
      });
    
  }


   
  const filterDataDon=()=>{

    

    const startDateDon=document.getElementById('startDateDon').value
    const endDateDon=document.getElementById('endDateDon').value

    axios.put(`${api_url}/donation/getSingle/${localStorage.getItem("userRegister")}`,{'startDate':startDateDon,'endDate':endDateDon}).then(
      (resp) => {
        console.log(resp.data)
          setdonation(resp.data.data)
      });
    
  }


  const filterDataCard=(e)=>{
    
    

    const startDateCard=document.getElementById('startDateCard').value
    const endDateCard=document.getElementById('endDateCard').value

    axios.put(`${api_url}/card/getSingle/${localStorage.getItem("userRegister")}`,{'startDate':startDateCard,'endDate':endDateCard}).then(
      (resp) => {
        console.log(resp.data)
          setcard(resp.data.data)
      });
    
  }





  useEffect(()=>{
    dataDon()
    $(document).ready(function () {
      setTimeout(function () {
        $("#AllData3").DataTable({
          pagingType: "full_numbers",
          pageLength: 10,
          processing: true,
          bDestroy: true,
          // dom: 'Bfrtip',
          // buttons: ['copy', 'csv', 'print']
        });
      }, 1000);
    });





    dataApp()

    $(document).ready(function () {
      setTimeout(function () {
        $("#AllData").DataTable({
          pagingType: "full_numbers",
          pageLength: 10,
          processing: true,
          bDestroy: true,
          // dom: 'Bfrtip',
          // buttons: ['copy', 'csv', 'print']
        });
      }, 1000);
    });








    dataCard()


    $(document).ready(function () {
      setTimeout(function () {
        $("#AllData2").DataTable({
          pagingType: "full_numbers",
          pageLength: 10,
          processing: true,
          bDestroy: true,
          // dom: 'Bfrtip',
          // buttons: ['copy', 'csv', 'print']
        });
      }, 1000);
    });

  },[])







 
  return (
    <>
      <div className="p-4">
        <h3 className="text-start p-2">Monthly Revenue Report</h3>

        <ul class="nav nav-pills  mb-3" id="pills-tab" role="tablist">
          <li class="nav-item" role="presentation">
            <button
              class="nav-link active"
              id="pills-home-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-home"
              type="button"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              onClick={dataDon}
            >
              Donation
            </button>
          </li>
          <li class="nav-item mx-2" role="presentation">
            <button
              class="nav-link"
              id="pills-profile-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-profile"
              type="button"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              onClick={dataApp}
            >
              Appointment
            </button>
          </li>
          <li class="nav-item" role="presentation">
            <button
              class="nav-link"
              id="pills-contact-tab"
              data-bs-toggle="pill"
              data-bs-target="#pills-contact"
              type="button"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
              onClick={dataCard}
            >
            Registration Card 
            </button>
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <div className=" card container border-0 bg-white p-4 shadow">
                
            <div className="row pb-4 align-items-center" >
                <div className="col-md-4">
                <h5>Donation Transaction</h5>
                </div>
                <div className="col-md-8">
                  <div className="row align-items-center" >
                    <div className="col-md-4">
                      <label>Start Date</label>
                      <input className="form-control" type="date" name='startDateDon' id='startDateDon' />
                    </div>
                    <div className="col-md-4">
                    <label>End Date</label>
                      <input className="form-control" type="date" name='endDateDon' id='endDateDon' />
                    </div>
                    <div className="col-md-4 pt-3">
                  
                      <button className="btn btn-primary" onClick={filterDataDon} type="button" >Search</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" row  p-3  d-flex justify-content-between"></div>
              <table class="table" id="AllData3">
                <thead>
                  <tr>
                    <th scope="col">SL</th>
                    <th scope="col">Username</th>
                    <th scope="col">Payment</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Status</th>
                    <th scope="col">Date</th>
                  </tr>
                </thead>
                <tbody>
                  {donation[0]?.donation?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{donation[0]?.username}</td>
                          <td>{item?.paymentType}</td>
                          <td>{item?.amount}</td>
                          <td>{item?.status==='pending'?
                            <span className="badge text-bg-warning">pending</span>:<span className="badge text-bg-success">approved</span>}</td>
                          <td>{item?.createdAt}</td>
                          {/* <td>{item?.password}</td> */}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <div className=" card container border-0 bg-white p-4 shadow">
            <div className="row pb-4 align-items-center" >
                <div className="col-md-4">
                <h5>Appointment Transaction</h5>
                </div>
                <div className="col-md-8">
                  <div className="row align-items-center" >
                    <div className="col-md-4">
                      <label>Start Date</label>
                      <input className="form-control" type="date" name='startDateApp' id='startDateApp' />
                    </div>
                    <div className="col-md-4">
                    <label>End Date</label>
                      <input className="form-control" type="date" name='endDateApp' id='endDateApp' />
                    </div>
                    <div className="col-md-4 pt-3">
                  
                      <button className="btn btn-primary" onClick={filterDataApp} type="button" >Search</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" row  p-3  d-flex justify-content-between"></div>
              <table class="table" id="AllData">
                <thead>
                  <tr>
                    <th scope="col">SL</th>
                    <th scope="col">Doctor Name</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Appointment Time</th>
                    <th scope="col">Appointment Date</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                    {appointment[0]?.appointmentU?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.doctor.name}</td>
                          <td>{item?.users?.username}</td>
                          <td>{item?.appointmentTime}</td>
                          <td>{item?.appointmentDate}</td>
                          <td>{item?.status==='pending'?
                            <span className="badge text-bg-warning">{item?.status}</span>:<span className="badge text-bg-success">{item?.status}</span>}</td>
                          {/* <td>{item?.password}</td> */}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <div className=" card container border-0 bg-white p-4 shadow">

             
            <div className="row pb-4 align-items-center" >
                <div className="col-md-4">
                <h5>Card Transaction</h5>
                </div>
                <div className="col-md-8">
                  <div className="row align-items-center" >
                    <div className="col-md-4">
                      <label>Start Date</label>
                      <input className="form-control" type="date" name='startDateCard' id='startDateCard' />
                    </div>
                    <div className="col-md-4">
                    <label>End Date</label>
                      <input className="form-control" type="date" name='endDateCard' id='endDateCard' />
                    </div>
                    <div className="col-md-4 pt-3">
                  
                      <button className="btn btn-primary" onClick={filterDataCard} type="button" >Search</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className=" row  p-3  d-flex justify-content-between"></div>
              <table class="table" id="AllData2">
                <thead>
                  <tr>
                    <th scope="col">SL</th>
                    <th scope="col">Name</th>
                    <th scope="col">Mr</th>
                    <th scope="col">Age</th>
                    <th scope="col">Amount</th>
                    <th scope="col">Phone</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {card[0]?.card?.map((item, index) => {
                    return (
                      <>
                        <tr>
                          <th scope="row">{index + 1}</th>
                          <td>{item?.name}</td>
                          <td>{item?.mr}</td>
                          <td>{item?.age}</td>
                          <td>{item?.amount}</td>
                          <td>{item?.phone}</td>
                          <td>{item?.status==='pending'?
                            <span className="badge text-bg-warning">{item?.status}</span>:<span className="badge text-bg-success">{item?.status}</span>}</td>
                          {/* <td>{item?.password}</td> */}
                        </tr>
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default HistoryTranscation;
